import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MapPinIcon } from "@heroicons/react/24/solid";
import { members } from "../data/members";
import { slugify, handleScroll } from "../utils";
import { HashLink as Link } from 'react-router-hash-link';


const MemberDetails = () => {
  let { memberSlug } = useParams();

  const member = members.find((m) => slugify(m.name) === memberSlug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!member) {
    return (
      <div>
          <h1>Member Not Found</h1>
          <p>Sorry, the requested member was not found.</p>
      </div>
    );
  }

  return (
    < section id="details" className="text-gray-700 body-font" >
      <div className="container max-w-lg mx-auto my-[10%] shadow-lg rounded-lg overflow-hidden">
        <div className="p-4 flex flex-col items-center mt-20">
          <img src={`/${member.image}`} alt={member.name} className="w-48 h-48 object-cover rounded-full border-2 border-gray-300" />
          <h2 className="text-2xl font-bold my-2">{member.title} {member.first_name} {member.last_name}</h2>
          <h3 className="text-xl text-gray-800 mb-2">{member.role}</h3>
          <h4>
            <MapPinIcon className="w-5 inline-block mb-2" />
            {member.room}</h4>
        </div>
        <div className="px-4 pb-4">
          <p className="text-left">{member.bio}</p>
        </div>
        <div className="px-4 pt-4 pb-4 bg-gray-100">
          <h4 className="text-sm font-bold text-gray-600">Skills</h4>
          <ul className="list-disc pl-5">
            {member.skills.map((skill, index) => (
              <li key={index} className="text-gray-700">{skill}</li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col items-center justify-center">

      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
        <Link smooth to="/#members" onClick={() => handleScroll('members')}>
          <button className="w-full bg-custom-blue hover:bg-custom-blue-dark text-white text-lg font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline">Back</button>
        </Link>
      </div>
    </div>
    </section >
  );
};

export default MemberDetails;
