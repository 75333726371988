import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { handleScroll } from "../utils";

export default function Navbar() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      handleScroll(location.state.scrollTo);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-[#00A6D6] md:fixed top-0 left-0 w-full z-10">
      <div className="container mx-auto flex flex-wrap flex-col md:flex-row items-center justify-between">
        <a href="https://tudelft.nl" className="flex title-font font-medium items-center text-white md:mb-0">
          <img className="h-20" src="/img/TUDelft-logo.png" alt="TUD Logo" />
        </a>
        <div className="title-font font-medium text-white text-center sm:text-right mx-auto mb-5 md:mb-0 px-5">
          <Link to="/#about" className="ml-0 text-xl font-semibold" onClick={() => handleScroll('about')}>
            Research Engineering and Infrastructure Team
          </Link>
        </div>
        <button 
          className="md:hidden text-white p-2 w-full flex justify-end mb-4"
          onClick={toggleMenu}
        >
          {isOpen ? (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </button>
        <nav className={`${isOpen ? 'flex' : 'hidden'} my-5 md:flex text-white flex-col md:flex-row flex-wrap items-center text-base justify-end mx-auto text-lg w-full md:w-auto`}>
          <Link smooth to="/#about" onClick={() => {handleScroll('about'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark mb-2 md:mb-0">
            About
          </Link>
          <Link smooth to="/#services" onClick={() => {handleScroll('services'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark mb-2 md:mb-0">
            Services
          </Link>
          <Link smooth to="/#members" onClick={() => {handleScroll('members'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark mb-2 md:mb-0">
            Members
          </Link>
          <Link smooth to="/#jobs" onClick={() => {handleScroll('jobs'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark mb-2 md:mb-0">
            Jobs
          </Link>
          <Link smooth to="/#projects" onClick={() => {handleScroll('projects'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark mb-2 md:mb-0">
            Projects
          </Link>
          <Link smooth to="/#courses" onClick={() => {handleScroll('courses'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark mb-2 md:mb-0">
            Courses
          </Link>
          <Link smooth to="/#contact" onClick={() => {handleScroll('contact'); toggleMenu();}} className="mr-5 hover:text-custom-blue-dark">
            Contact
          </Link>
        </nav>
      </div>
    </header>
  );
}