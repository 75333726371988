import React from "react";
import { CommandLineIcon as Icon } from "@heroicons/react/24/solid";
import { HashLink as Link } from 'react-router-hash-link';
import { handleScroll } from "../utils";
import { services } from "../data/services";

export default function Services() {
  return (
    <section id="services" className="bg-gray-100 py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <Icon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">Services</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            REIT provides four different formats of services. Add-hoc support is free of charge and does not require a formal application. 
            The other three formats require an application, and will undergo a review process. Depending on the length of the project, funding may be required. 
            Research Engineers usually work on two projects at a time.
          </p>
        </div>

        <div className="flex flex-wrap mt-10 max-w-6xl mx-auto">
          {services.map((service, index) => (
            <div key={index} className="p-4 md:w-1/2 w-full text-left">
              <div className="h-full bg-white p-8 rounded-lg text-left shadow-md rounded-lg">
                <p className="leading-relaxed uppercase text-gray-700">{service.name}</p>
                <div className="inline-flex items-center mt-4">
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-medium text-gray-700">
                      {service.timespan}
                    </span>
                    <span className="title-font font-medium text-gray-700">
                      {service.funding}
                    </span>
                    <span className="font-medium">
                      {service.proposal}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <Link 
              smooth 
              to="/#workwithus" 
              onClick={() => handleScroll('workwithus')} 
              className="inline-block mt-8"
            >
              <button className="bg-white border-4 border-color-black hover:bg-gray-200 text-lg font-bold py-3 px-6 mt-10 mx-auto rounded-full transition duration-300">
                How to start?
              </button>
            </Link>
        </div>
      </div>
    </section>
  );
}
