import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UsersIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { members } from "../data/members";
import { slugify } from "../utils";

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export default function Members() {
  const [shuffledMembers, setShuffledMembers] = useState([]);

  useEffect(() => {
    setShuffledMembers(shuffleArray(members));
  }, []);

  return (
    <section id="members" className="bg-gray-100 py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <UsersIcon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">REIT Members</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            We are a team of professionals who are specialized in developing software tools, systems, and applications to support research activities in various domains, such as science and engineering. We combine expertise in software engineering with an understanding of research methodologies and domain-specific requirements to create robust, efficient, and scalable software solutions.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          {shuffledMembers.map((member, index) => (
            <div key={index} className="p-3">
              <Link
                to={`/members/${slugify(member.name)}`}
                className="relative group block"
              >
                <div className="relative w-full h-40 xs:h-60 sm:h-80 overflow-hidden rounded-lg shadow-md">
                  <img
                    alt="gallery"
                    className="absolute inset-0 w-full h-full object-cover object-center"
                    src={member.image}
                  />
                  <div className="absolute bottom-0 w-full bg-gray-900 bg-opacity-60 text-white py-1 px-2 text-center">
                    <h1 className="title-font text-base sm:text-sm font-medium">
                      {member.first_name} {member.last_name}
                    </h1>
                  </div>
                </div>
                <div className="absolute inset-0 flex flex-col justify-center items-center w-full bg-white bg-opacity-80 opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 hidden sm:flex">
                  <h1 className="title-font text-lg font-medium text-gray-700 mb-3">
                    {member.first_name} {member.last_name}
                  </h1>
                  <h2 className="tracking-widest text-sm title-font font-medium text-blue-500 mb-1">
                    {member.role}
                  </h2>
                  <ul className="leading-relaxed my-3 text-left text-md">
                    {member.skills.map((skill, skillIndex) => (
                      <li key={skillIndex}>{skill}</li>
                    ))}
                  </ul>
                  <div className="text-left mx-auto">
                    <MapPinIcon className="w-5 inline-block mb-2" />
                    {member.room}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
