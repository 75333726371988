import React from "react";

export default function QuickConnect() {
  return (
    <section id="about" className="pb-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="flex flex-col items-center justify-center">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
            <a href="mailto:reit-ewi@tudelft.nl?subject=[Contact]">
              <button className="bg-custom-blue hover:bg-custom-blue-dark text-white font-bold py-3 px-8 rounded-full transition duration-300">
                Contact us
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
