import React, { useEffect } from "react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { HashLink as Link } from 'react-router-hash-link';
import { handleScroll } from "../utils";
import { useLocation } from "react-router-dom";

export default function Contact() {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      handleScroll(location.state.scrollTo);
    }
  }, [location]);

  return (
    <section id="contact" className="bg-gray-100 py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <MapPinIcon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">How to find us?</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            REIT is part of the Faculty of Electrical Engineering, Mathematics, and Computer Science of TU Delft. You can find us in Building 28 on campus. 
            Our team members are distributed across the building and other locations. <strong>The best way to contact us is to talk to us directly.</strong> You can find the
            room information in the member profiles. You can also get in touch via email or MS-Teams.
          </p>
        </div>

        <div className="flex justify-center">
          <Link smooth to="/#members" onClick={() => handleScroll('members')}>
            <button className="bg-white border-4 border-color-blue hover:bg-gray-200 text-lg font-bold py-3 px-6 mt-5 rounded-full transition duration-300">
              Find members
            </button>
          </Link>
        </div>

        {/* Contact Information */}
        <div className="text-lg text-gray-600 max-w-3xl mx-auto text-left mt-20 p-5 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p className="underline">Address</p>
          <p>Van Mourik Broekmanweg 6, 2628 XE, Delft</p>
          <p>The Netherlands</p>

          <p className="underline mt-5">E-mail</p>
          <a href="mailto:reit-ewi@tudelft.nl" className="text-blue-500 hover:underline">reit-ewi@tudelft.nl</a>
        </div>
      </div>
    </section>
  );
}
