
export const services = [
    {
      name: "Ad-hoc support",
      timespan: "Less than 1 hour",
      image: "img/people-1.png",
      funding: "Free service",
      proposal: ""
    },
    {
      name: "Short project",
      timespan: "Less than two weeks",
      image: "img/people-2.png",
      funding: "Free service",
      proposal: "Proposal required"
    },
    {
      name: "Medium project",
      timespan: "Less than six months",
      image: "img/people-1.png",
      funding: "Funding preferred",
      proposal: "Proposal required"
  
    },
    {
      name: "Long project",
      timespan: "More than six months",
      image: "img/people-2.png",
      funding: "Funding required",
      proposal: "Proposal required"
    },
  ];
  