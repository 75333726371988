import React from "react";

export default function Footer() {
  return (
    <footer className="bg-[#00A6D6] w-full z-10">
      
      <div className="container mx-auto flex flex-wrap flex-col md:flex-row items-center justify-between p-5"> {/* Add justify-between */}
      
      <p className="text-white">
        <b>Contact<br /></b>
        Address: Van Mourik Broekmanweg 6, 2628 XE, Delft<br />
        Email: <a href="mailto:reit-ewi@tudelft.nl">reit-ewi@tudelft.nl</a><br />
      </p>
      </div>
    </footer>
  );
}
